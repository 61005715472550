import { render, staticRenderFns } from "./inviteUsersModalContent.vue?vue&type=template&id=bd2535c4&scoped=true"
import script from "./inviteUsersModalContent.vue?vue&type=script&lang=js"
export * from "./inviteUsersModalContent.vue?vue&type=script&lang=js"
import style0 from "./inviteUsersModalContent.vue?vue&type=style&index=0&id=bd2535c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd2535c4",
  null
  
)

export default component.exports