import { render, staticRenderFns } from "./newOrder.vue?vue&type=template&id=39fe194e&scoped=true"
import script from "./newOrder.vue?vue&type=script&lang=js"
export * from "./newOrder.vue?vue&type=script&lang=js"
import style0 from "./newOrder.vue?vue&type=style&index=0&id=39fe194e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39fe194e",
  null
  
)

export default component.exports