import { render, staticRenderFns } from "./admin.vue?vue&type=template&id=db51c72c&scoped=true"
import script from "./admin.vue?vue&type=script&lang=js"
export * from "./admin.vue?vue&type=script&lang=js"
import style0 from "./admin.vue?vue&type=style&index=0&id=db51c72c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db51c72c",
  null
  
)

export default component.exports